<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <div>
        <v-row>
            <v-col>
                <!--<main-img></main-img>-->
                <div class="" style="width: 100%;">
                    <v-row class="sub-title-img-pc hidden-sm-and-down" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                이용안내
                            </h1>
                        </v-col>
                    </v-row>
                    <v-row class="sub-title-img-mo hidden-md-and-up" align="center" justify="center">
                        <v-col
                                class="text-center"
                                cols="12"
                        >
                            <h1 class="text-h4 font-weight-bold white--text">
                                이용안내
                            </h1>
                        </v-col>
                    </v-row>
                </div>
            </v-col>
            <!--<v-col>
                <v-row class="main-title-img-5" align="center" justify="center">


                </v-row>
            </v-col>-->
        </v-row>
        <v-row>
            <v-col>
                <v-container class="my-8">
                    <v-row>
                        <v-col cols="12" md="12" class="">
                            <h1 class="about-sub-title">기본사항</h1>
                        </v-col>

                        <v-col cols="12" md="9"  v-resize="onResize">
                            <div class="ql-editor">
                                <span v-html="textBasics"></span>
                            </div>

                        </v-col>
                    </v-row>

                    <v-row ref="1">
                        <v-col cols="12" md="12" class="">
                            <h1 class="about-sub-title">유의사항</h1>
                        </v-col>
                        <v-col cols="12" md="9">
                            <div class="ql-editor">
                                <span v-html="textNotice"></span>
                            </div>

                        </v-col>
                    </v-row>

                    <v-row ref="2">
                        <v-col cols="12" md="12" class="">
                            <h1 class="about-sub-title">환불규정</h1>
                        </v-col>
                        <v-col cols="12" md="9">
                            <div class="ql-editor">
                                <span v-html="textRefund"></span>
                            </div>

                        </v-col>
                    </v-row>

                    <v-row ref="3">
                        <v-col>
                            <v-container class="my-8">
                                <v-row>
                                    <v-col cols="12" md="12" class="mb-5">
                                        <h1 class="about-sub-title">이용요금</h1>
                                    </v-col>
                                    <v-col cols="12" md="12" class="" v-if="!isMob">
                                        <table class="table-room" border="1">
                                            <tr>
                                                <th>객실명</th>
                                                <th>인원</th>
                                                <!--<th>평형</th>-->
                                                <!--<th>형태</th>-->
                                                <th>구분</th>
                                                <th>비수기</th>
                                                <th>준성수기</th>
                                                <th>성수기</th>
                                            </tr>
                                            <template v-if="campList.length> 0" v-for="(item, i) in campList">
                                                <tr>
                                                    <td rowspan="2">캠핑{{item.roomGubun}}</td>
                                                    <td rowspan="2">기준{{item.stdNop}}/최대{{item.maxNop}}</td>
                                                    <!--<td rowspan="2">{{item.roomArc}}</td>-->
                                                    <!--<td rowspan="2">{{item.roomForm}}</td>-->
                                                    <td>주중</td>
                                                    <td>{{item.osrWe | comma}}</td>
                                                    <td>{{item.spsrWe | comma}}</td>
                                                    <td>{{item.psrWe | comma}}</td>
                                                </tr>
                                                <tr>
                                                    <td>주말</td>
                                                    <td>{{item.osrWd | comma}}</td>
                                                    <td>{{item.spsrWd | comma}}</td>
                                                    <td>{{item.psrWd | comma}}</td>
                                                </tr>
                                            </template>
                                            <template v-if="roomList.length> 0" v-for="(item, i) in roomList">
                                                <tr>
                                                    <td rowspan="2">{{item.roomNm}}</td>
                                                    <td rowspan="2">기준{{item.stdNop}}/최대{{item.maxNop}}</td>
                                                    <!--<td rowspan="2">{{item.roomArc}}</td>-->
                                                    <!--<td rowspan="2">{{item.roomForm}}</td>-->
                                                    <td>주중</td>
                                                    <td>{{item.osrWe | comma}}</td>
                                                    <td>{{item.spsrWe | comma}}</td>
                                                    <td>{{item.psrWe | comma}}</td>
                                                </tr>
                                                <tr>
                                                    <td>주말</td>
                                                    <td>{{item.osrWd | comma}}</td>
                                                    <td>{{item.spsrWd | comma}}</td>
                                                    <td>{{item.psrWd | comma}}</td>
                                                </tr>
                                            </template>
                                        </table>
                                    </v-col>
                                    <v-col v-else>
                                        <table class="table-room" border="1">
                                            <colgroup>
                                                <col style="width:20%"/>
                                                <col style="width:20%"/>
                                                <col style="width:20%"/>
                                                <col style="width:20%"/>
                                                <col style="width:20%"/>
                                            </colgroup>
                                            <tr>
                                                <th>객실<br>(인원)</th>
                                                <th>구분</th>
                                                <th>비수기</th>
                                                <th>준성수기</th>
                                                <th>성수기</th>
                                            </tr>
                                            <template v-if="campList.length> 0" v-for="(item, i) in campList">
                                                <tr>
                                                    <td rowspan="2">캠핑{{item.roomGubun}}<br> ({{item.stdNop}}/{{item.maxNop}})</td>
                                                    <td>주중</td>
                                                    <td>{{item.osrWe | comma}}</td>
                                                    <td>{{item.spsrWe | comma}}</td>
                                                    <td>{{item.psrWe | comma}}</td>
                                                </tr>
                                                <tr>
                                                    <td>주말</td>
                                                    <td>{{item.osrWd | comma}}</td>
                                                    <td>{{item.spsrWd | comma}}</td>
                                                    <td>{{item.psrWd | comma}}</td>
                                                </tr>
                                            </template>
                                            <template v-if="roomList.length> 0" v-for="(item, i) in roomList">
                                                <tr>
                                                    <td rowspan="2">{{item.roomNm}}<br> ({{item.stdNop}}/{{item.maxNop}})</td>
                                                    <td>주중</td>
                                                    <td>{{item.osrWe | comma}}</td>
                                                    <td>{{item.spsrWe | comma}}</td>
                                                    <td>{{item.psrWe | comma}}</td>
                                                </tr>
                                                <tr>
                                                    <td>주말</td>
                                                    <td>{{item.osrWd | comma}}</td>
                                                    <td>{{item.spsrWd | comma}}</td>
                                                    <td>{{item.psrWd | comma}}</td>
                                                </tr>
                                            </template>
                                        </table>
                                    </v-col>


                                </v-row>
                            </v-container>
                        </v-col>
                    </v-row>


                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import MainImg from '../../components/MainImg'
    export default {
        name: 'UserInfo',
        components: {
            MainImg
        },
        data: () => ({
            comnList : [],
            textBasics: "",
            textRefund: "",
            textNotice: "",
            roomList: [],
            campList: [],
            isMob: false,

        }),
        created: function(){
            // 맨위로
            window.scrollTo(0,0);
            if (window.innerWidth < 700) {

                this.isMob = true;

            } else {

                this.isMob = false;

            }

            this.getPageInfo();

        },
        methods: {
            getPageInfo(){


                let formdata = {comnGubuns:  ['textNotice','textRefund','textBasics'],};

                if (this.isMob){

                    formdata['comnOrder'] = '1';

                }else {
                    formdata['comnOrder'] = '0';
                }

                return this.$store.dispatch("admin/getComn", formdata)
                    .then((resp) => {

                        if (resp.message === undefined) {
                            alert("잘못된 요청입니다.");
                            window.history.back();
                            return;
                        }

                        this.comnList = resp.message;

                        let _this = this;

                        this.comnList.forEach(function (e) {

                            switch (e.comnGubun) {
                                case 'textNotice':{
                                    _this.textNotice = e.comnCont;

                                    break;
                                }
                                case 'textRefund':{
                                    _this.textRefund = e.comnCont;
                                    break;
                                }
                                case 'textBasics':{
                                    _this.textBasics = e.comnCont;
                                    break;
                                }
                            }

                        });

                        //this.quill.root.innerHTML = this.comnInfo.comnCont;
                        this.getRoomInfo();

                    })
                    .catch((err) => {
                    })
            },
            onResize() {

                let isMob = false;

                if (window.innerWidth < 700) {

                    isMob = true;

                } else {

                    isMob = false;

                }

                if(this.isMob !== isMob){

                    this.isMob = isMob;

                    this.getPageInfo();
                }
            },
            getRoomInfo(){
                return this.$store.dispatch("room/getRoomList", {useYn: "Y"})
                    .then((resp) => {
                        setTimeout(() => {

                            //this.roomList = resp.message;
                            this.setTariff(resp.message);

                        }, 300)
                    })
                    .catch((err) => {
                    })
            },
            setTariff(val){

                this.roomList = [];
                this.campList = [];

                for(let i = 0 ; val.length > i ; i++){
                    if(val[i].roomGubun === "R"){
                        this.roomList.push(val[i]);
                    }
                }

                for(let i = 0 ; val.length > i ; i++){
                    if(val[i].roomGubun === "A"){
                        this.campList.push(val[i]);
                        break;
                    }
                }
                for(let i = 0 ; val.length > i ; i++){
                    if(val[i].roomGubun === "B"){
                        this.campList.push(val[i]);
                        break;
                    }
                }
                for(let i = 0 ; val.length > i ; i++){
                    if(val[i].roomGubun === "C"){
                        this.campList.push(val[i]);
                        break;
                    }
                }

                for(let i = 0 ; val.length > i ; i++){
                    if(val[i].roomGubun === "D"){
                        this.campList.push(val[i]);
                        break;
                    }
                }

                this.setPage();

            },
            setPage(){
                this.$nextTick(() => {
                    let pageId = this.$getParameterByName('pageId');

                    if (pageId !== "") {
                        setTimeout(() => {
                            this.scrollMeTo(pageId);
                        }, 300);
                        //this.hash("/place/main", pageId);

                    }
                })
            },
            scrollMeTo(refName) {
                var element = this.$refs[refName];
                var top = element.offsetTop;

                window.scrollTo(0, top);
            },

        },
        mounted() {
            this.$nextTick(() => {

            })
        },
    }
</script>

